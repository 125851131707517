import { Field } from '@/components/field';

import './index.css';

import { Cross2Icon } from '@radix-ui/react-icons';
import * as Sentry from '@sentry/nextjs';
import { Dialog } from 'radix-ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@/components/button';

export const FormDialog = ({ title, form, children }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const { register, handleSubmit, formState, reset } = useForm();

  const onSubmit = async (data) => {
    setError(null);

    try {
      const response = await fetch('/api/countdown', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Form submit error`);
      }

      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureMessage('Form submitted successfully');
      });

      setOpen(false);
      reset();
    } catch (error) {
      setError(error);

      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureException(error);
      });

      throw error;
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="form-dialog__overlay" />

        <Dialog.Content
          className="form-dialog__content"
          aria-describedby={undefined}
        >
          <div className="form-dialog__header">
            <Dialog.Title className="form-dialog__title">{title}</Dialog.Title>

            <Dialog.Close asChild>
              <button className="form-dialog__icon-button" aria-label="Close">
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </div>

          <form className="form-dialog__form" onSubmit={handleSubmit(onSubmit)}>
            {form.lastNameField || form.firstNameField ? (
              <div className="form-dialog__line">
                {form.firstNameField ? (
                  <Field
                    placeholder={form.firstNameField}
                    autoComplete="given-name"
                    {...register('firstName', {
                      required: true,
                    })}
                    error={
                      formState.errors.firstName
                        ? 'Veuillez renseigner votre prénom'
                        : null
                    }
                  />
                ) : null}

                {form.lastNameField ? (
                  <Field
                    placeholder={form.lastNameField}
                    autoComplete="family-name"
                    {...register('lastName', {
                      required: true,
                    })}
                    error={
                      formState.errors.lastName
                        ? 'Veuillez renseigner votre nom de famille'
                        : null
                    }
                  />
                ) : null}
              </div>
            ) : null}

            {form.emailLabel || form.phoneNumberField ? (
              <div className="form-dialog__line">
                {form.emailLabel ? (
                  <Field
                    placeholder={form.emailLabel}
                    type="email"
                    autoComplete="email"
                    {...register('email', {
                      required: true,
                    })}
                    error={
                      formState.errors.email
                        ? 'Veuillez renseigner votre e-mail'
                        : null
                    }
                  />
                ) : null}

                {form.phoneNumberField ? (
                  <Field
                    placeholder={form.phoneNumberField}
                    type="tel"
                    autoComplete="tel"
                    {...register('phoneNumber')}
                  />
                ) : null}
              </div>
            ) : null}

            {form.companyField || form.roleField ? (
              <div className="form-dialog__line">
                {form.companyField ? (
                  <Field
                    placeholder={form.companyField}
                    autoComplete="organization"
                    {...register('company', {
                      required: true,
                    })}
                    error={
                      formState.errors.company
                        ? 'Veuillez renseigner le nom de votre entreprise'
                        : null
                    }
                  />
                ) : null}

                {form.roleField ? (
                  <Field
                    placeholder={form.roleField}
                    autoComplete="role"
                    {...register('role', {
                      required: true,
                    })}
                    error={
                      formState.errors.role
                        ? 'Veuillez renseigner votre fonction'
                        : null
                    }
                  />
                ) : null}
              </div>
            ) : null}

            <Button
              className="form-dialog__cta"
              type="submit"
              loading={formState.isSubmitting}
            >
              {form.submitLabel && form.submitLabelMobile ? (
                <>
                  <span className="desktop-only">{form.submitLabel}</span>
                  <span className="mobile-only">{form.submitLabelMobile}</span>
                </>
              ) : (
                form.submitLabel || form.submitLabelMobile || 'Envoyer'
              )}
            </Button>

            {error ? (
              <p className="form-dialog__error">
                {form.errorMessage ||
                  "Une erreur est survenue lors de l'envoi du formulaire, veuillez réessayer plus tard."}
              </p>
            ) : null}
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
