'use client';

import './index.css';
import './content.css';
import './form.css';

import * as Sentry from '@sentry/nextjs';
import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useForm } from 'react-hook-form';

import { Button } from '@/components/button';
import { Field } from '@/components/field';
import { MaxWidth } from '@/components/max-width';

import { BREVO_LISTS } from '@/helpers/brevo/lists';
import { hasText, render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

export const Join = ({ blok }) => {
  const pathname = usePathname();

  const TitleEl = blok.titleLevel || 'h2';

  const [showAllItems, setShowAllItems] = useState(false);

  const [error, setError] = useState(null);

  const { register, handleSubmit, formState } = useForm();

  const onSubmit = async (data) => {
    setError(null);

    try {
      const response = await fetch('/api/demo-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          brevoListId: BREVO_LISTS.BECOME_MEMBER,
          websiteSource: pathname,
        }),
      });

      if (!response.ok) {
        throw new Error('Demo form submit error');
      }

      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureMessage('Demo form submitted successfully');
      });
    } catch (error) {
      setError(error);

      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureException(error);
      });

      throw error;
    }
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('join', {
        'join--success': formState.isSubmitSuccessful,
      })}
    >
      <MaxWidth className="join__max-width">
        <div className="join__left">
          {formState.isSubmitSuccessful ? (
            <div className="join__success">
              <p>{render(blok.success)}</p>
            </div>
          ) : (
            <>
              {blok.title ? (
                <TitleEl
                  className="join__title"
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(blok.title),
                  }}
                />
              ) : null}

              <form className="join__form" onSubmit={handleSubmit(onSubmit)}>
                {blok.lastNameField || blok.firstNameField ? (
                  <div className="join__line">
                    {blok.firstNameField ? (
                      <Field
                        theme="dark"
                        placeholder={blok.firstNameField}
                        autoComplete="given-name"
                        {...register('firstName', {
                          required: true,
                        })}
                        error={
                          formState.errors.firstName
                            ? 'Veuillez renseigner votre prénom'
                            : null
                        }
                      />
                    ) : null}

                    {blok.lastNameField ? (
                      <Field
                        theme="dark"
                        placeholder={blok.lastNameField}
                        autoComplete="family-name"
                        {...register('lastName', {
                          required: true,
                        })}
                        error={
                          formState.errors.lastName
                            ? 'Veuillez renseigner votre nom de famille'
                            : null
                        }
                      />
                    ) : null}
                  </div>
                ) : null}

                {blok.emailField || blok.phoneNumberField ? (
                  <div className="join__line">
                    {blok.emailField ? (
                      <Field
                        theme="dark"
                        placeholder={blok.emailField}
                        type="email"
                        autoComplete="email"
                        {...register('email', {
                          required: true,
                        })}
                        error={
                          formState.errors.email
                            ? 'Veuillez renseigner votre e-mail'
                            : null
                        }
                      />
                    ) : null}

                    {blok.phoneNumberField ? (
                      <Field
                        theme="dark"
                        placeholder={blok.phoneNumberField}
                        type="tel"
                        autoComplete="tel"
                        {...register('phoneNumber')}
                      />
                    ) : null}
                  </div>
                ) : null}

                {blok.companyField || blok.roleField ? (
                  <div className="join__line">
                    {blok.companyField ? (
                      <Field
                        theme="dark"
                        placeholder={blok.companyField}
                        autoComplete="organization"
                        {...register('company', {
                          required: true,
                        })}
                        error={
                          formState.errors.company
                            ? 'Veuillez renseigner le nom de votre entreprise'
                            : null
                        }
                      />
                    ) : null}

                    {blok.roleField ? (
                      <Field
                        theme="dark"
                        placeholder={blok.roleField}
                        autoComplete="role"
                        {...register('role', {
                          required: true,
                        })}
                        error={
                          formState.errors.role
                            ? 'Veuillez renseigner votre fonction'
                            : null
                        }
                      />
                    ) : null}
                  </div>
                ) : null}

                {blok.textField ? (
                  <div className="join__line">
                    <Field
                      theme="dark"
                      as="textarea"
                      placeholder={blok.textField}
                      {...register('message')}
                    />
                  </div>
                ) : null}

                <Button
                  theme="blue"
                  className="join__cta"
                  type="submit"
                  loading={formState.isSubmitting}
                >
                  {blok.submitLabel || 'Envoyer'}
                </Button>

                {error ? (
                  <p className="join__error">
                    Une erreur est survenue lors de l'envoi de votre formulaire,
                    veuillez réessayer plus tard.
                  </p>
                ) : null}
                {hasText(blok.notice) ? (
                  <div className="join__notice">{render(blok.notice)}</div>
                ) : null}
              </form>
            </>
          )}
        </div>

        <div className="join__content">
          <div className="join__content__wrap">
            {blok.supTitle ? (
              <p className="join__sup-title">{blok.supTitle}</p>
            ) : null}

            {hasText(blok.price) ? (
              <div className="join__price">{render(blok.price)}</div>
            ) : null}

            {hasText(blok.description) ? (
              <div className="join__description">
                {render(blok.description)}
              </div>
            ) : null}
          </div>

          {blok.items.length > 0 ? (
            <div className="join__content__footer">
              <button
                className={classNames('join__list__show-more', {
                  'join__list__show-more--reverse': showAllItems,
                })}
                type="button"
                onClick={() => setShowAllItems((current) => !current)}
              >
                <span>{showAllItems ? blok.showLess : blok.showMore}</span>

                <img
                  src="/icons/chevron-down-white.svg"
                  alt=""
                  width="24"
                  height="24"
                />
              </button>

              <AnimateHeight duration={300} height={showAllItems ? 'auto' : 0}>
                <ul className="join__list__ul">
                  {blok.items.map((item) => (
                    <JoinListItem key={item._uid}>{item.content}</JoinListItem>
                  ))}
                </ul>
              </AnimateHeight>
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

const JoinListItem = ({ children }) => {
  return (
    <li className="join__list__item">
      <img src="/icons/checkmark-green.png" alt="" width="60" height="60" />

      <span>{children}</span>
    </li>
  );
};
