'use client';

import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import * as EmailValidator from 'email-validator';
import React, { useRef, useState } from 'react';

import './index.css';

import { usePathname } from 'next/navigation';

import { Button } from '@/components/button';
import {
  ArrowTopRightCirclePrimary,
  Check,
  SendIcon,
} from '@/components/icons';

import { render } from '@/helpers/storyblok/rich-text';

const STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const Form = ({ blok }) => {
  const pathname = usePathname();

  const form = blok?.form[0];

  const formRef = useRef(null);

  const [state, setState] = useState(STATE.IDLE);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [sector, setSector] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState(STATE.LOADING);

    const data = {
      site: 'Unlock-M',
      form: form.id,
      name,
      company,
      email,
      sector,
      message,
      websiteSource: pathname,
    };

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch('/api/form', options);
      if (!response.ok) {
        throw new Error(`${form.id} form submit error`);
      }
      setState(STATE.SUCCESS);
      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureMessage('Form submitted successfully');
      });
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureException(error);
      });
      setState(STATE.ERROR);
    }
  };

  const getSplitClass = (fields) => {
    const fieldCount = Object.values(fields).reduce(
      (count, field) => (field ? count + 1 : count),
      0,
    );
    return classnames('hero-form__form__split', {
      'hero-form__form__split--flex': fieldCount === 1,
    });
  };

  return (
    <>
      <div
        className={classnames('hero-form__form', {
          'hero-form__form--state-loading': state === STATE.LOADING,
          'hero-form__form--state-success': state === STATE.SUCCESS,
          'hero-form__form--state-error': state === STATE.ERROR,
        })}
      >
        {form.title ? (
          <div className="hero-form__form__title">{render(form.title)}</div>
        ) : null}
        <form onSubmit={handleSubmit} ref={formRef}>
          {form.name || form.company ? (
            <div
              className={getSplitClass({
                name: form.name,
                company: form.company,
              })}
            >
              {form.name ? (
                <div className="hero-form__form__input-container">
                  <input
                    className="hero-form__form__input"
                    type="text"
                    name="name"
                    placeholder={form.name}
                    autoComplete="name"
                    value={name}
                    onInput={(event) => setName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
              {form.company ? (
                <div className="hero-form__form__input-container">
                  <input
                    className="hero-form__form__input"
                    type="text"
                    name="company"
                    placeholder={form.company}
                    autoComplete="company"
                    value={company}
                    onInput={(event) => setCompany(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {form.email || form.sector ? (
            <div
              className={getSplitClass({
                email: form.email,
                sector: form.sector,
              })}
            >
              {form.email ? (
                <div className="hero-form__form__input-container">
                  <input
                    className="hero-form__form__input"
                    type="email"
                    name="email"
                    placeholder={form.email}
                    autoComplete="email"
                    value={email}
                    onInput={(event) => setEmail(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
              {form.sector ? (
                <div className="hero-form__form__input-container">
                  <input
                    className="hero-form__form__input"
                    type="text"
                    name="sector"
                    placeholder={form.sector}
                    autoComplete="sector"
                    value={sector}
                    onInput={(event) => setSector(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {form.message ? (
            <div className="hero-form__form__input-container-textarea">
              <textarea
                className="hero-form__form__textarea"
                name="message"
                placeholder={form.message}
                autoComplete="no"
                value={message}
                onInput={(event) => setMessage(event.target.value)}
                readOnly={state === STATE.LOADING}
              />
            </div>
          ) : null}

          {state === STATE.ERROR ? (
            <div className="hero-form__form__error">
              {render(form.errorMessage)}
            </div>
          ) : null}

          <div className="hero-form__form__cta">
            <Button
              theme="primary"
              rightIcon={<ArrowTopRightCirclePrimary />}
              disabled={
                [STATE.SUCCESS].includes(state) ||
                message === '' ||
                email === '' ||
                EmailValidator.validate(email) === false
              }
              loading={[STATE.LOADING].includes(state)}
            >
              {form.submit}
            </Button>
          </div>
        </form>
      </div>
      {state === STATE.SUCCESS ? (
        <div className="hero-form__form__success">
          <div className="hero-form__form__success__content">
            {form.component == 'discoveryForm' ? <SendIcon /> : null}

            <div className="hero-form__form__success__content__text">
              {render(form.successMessage)}
            </div>
          </div>
          <div className="hero-form__form__success__button">
            <p>{form.successButtonText}</p>
            <Check />
          </div>
        </div>
      ) : null}
    </>
  );
};
