'use client';

import './index.css';
import './slider.css';

import { storyblokEditable } from '@storyblok/react';

import { Button } from '@/components/button';
import { CountdownForm } from '@/components/countdown-form';
import { FormDialog } from '@/components/form-dialog';
import { InlineForm } from '@/components/inline-form';

import { hasText, render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import { HeroFormationSlider } from './slider';

export const HeroFormation = ({ blok }) => {
  const TitleEl = blok.titleLevel || 'h1';

  const form = blok.form?.[0];
  const popupForm = blok.popupForm?.[0];

  const hasCountdown = blok.countdownTitle || blok.countdownSubTitle;

  return (
    <div {...storyblokEditable(blok)} className="hero-formation">
      <div className="hero-formation__wrap">
        <HeroFormationSlider assets={blok.sliderAssets} />

        <div className="hero-formation__text">
          <p className="hero-formation__type">{blok.type}</p>

          <TitleEl
            className="hero-formation__title"
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(blok.title),
            }}
          />

          {/* <div className="hero-formation__price">
            {blok.price ? (
              <div className="hero-formation__price__amount">
                {formatCurrency(blok.price)}

                <sub> HT</sub>
              </div>
            ) : null}

            {hasText(blok.priceNote) ? (
              <div
                className={classNames('hero-formation__price__note', {
                  'hero-formation__price__note--tag': blok.priceTag,
                })}
              >
                {render(blok.priceNote)}
              </div>
            ) : null}
          </div> */}

          {hasText(blok.content) ? (
            <div className="hero-formation__content">
              {render(blok.content)}
            </div>
          ) : null}

          {form && !hasCountdown ? (
            <div className="hero-formation__form">
              <InlineForm
                emailInputId={form?.emailInputId}
                emailLabel={form?.emailLabel}
                submitLabel={form?.submitLabel}
                submitLabelMobile={form?.submitLabelMobile}
                successMessage={form?.successMessage}
                errorMessage={form?.errorMessage}
                codeConvertkit={form?.codeConvertkit}
                brevoListId={form?.brevoListId}
              />
            </div>
          ) : null}

          {blok.cta?.length > 0 ? (
            <div className="hero-formation__cta">
              {blok.cta.map((item) => {
                return (
                  <Button
                    key={item._uid}
                    link={item.link}
                    theme={item.theme || 'white'}
                    dangerouslySetInnerHTML={{
                      __html: markdownToHtml(item.title),
                    }}
                    {...storyblokEditable(item)}
                  />
                );
              })}
            </div>
          ) : null}

          {popupForm ? (
            <div className="hero-formation__cta">
              <FormDialog
                title={popupForm.popupTitle || popupForm.buttonLabel || ''}
                form={popupForm.form?.[0]}
              >
                <Button
                  theme={blok.cta?.length > 0 ? 'glass-white' : undefined}
                  type="button"
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(popupForm.buttonLabel),
                  }}
                />
              </FormDialog>
            </div>
          ) : null}

          {hasCountdown ? (
            <div className="hero-formation__countdown">
              <CountdownForm
                theme="simple"
                date={new Date(blok.countdownDate)}
                title={blok.countdownTitle}
                subtitle={blok.countdownSubTitle}
                form={blok.form?.[0]}
              />
            </div>
          ) : null}

          <p className="hero-formation__info">{blok.info}</p>
        </div>
      </div>
    </div>
  );
};
